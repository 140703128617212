import React from "react";
import Layout from "../components/layout/Layout";

export default () => (
  <Layout>
    <div className="pl-24 pr-24 pt-16 pb-10">
      <div>
        <h2 className="text-center text-2xl font-semibold pt-10 pb-10">
          Privacy Policy
        </h2>
        <p>
          This app is owned and operated by Crowdconnect and will be referred as
          “we”, “us” and “our” in this Privacy Policy. By using Yogost Australia app,
          you agree to the privacy policy of Crowdconnect which is set out
          below.&nbsp;Please read our privacy policy carefully to get a clear
          understanding of how we collect, use, protect or otherwise handle your
          Personally Identifiable Information in accordance with our website.
    </p>

        <h2 className="text-left font-semibold pt-10 pb-10">
          WHAT PERSONAL INFORMATION DO WE COLLECT FROM THE PEOPLE THAT VISIT OUR
          BLOG, WEBSITE OR APP?
    </h2>
        <p>
          When ordering or registering on our site, as appropriate, you may be asked
          to enter your name, email address, mailing address, phone number, credit
          card information or other details to help you with your experience.
    </p>
        <h2 className="text-left font-semibold pt-10 pb-10">WHEN DO WE COLLECT INFORMATION?</h2>
        <p>
          We collect information from you when you register on our site, subscribe
          to a newsletter or enter information on our site.
    </p>
        <h2 className="text-left font-semibold pt-10 pb-10">HOW DO WE USE YOUR INFORMATION?</h2>
        <p>
          We may use the information we collect from you when you register, make a
          purchase, sign up for our newsletter, respond to a survey or marketing
          communication, surf the website, or use certain other site features in the
          following ways:
    </p>
        <ul>
          <li>
            To personalize user’s experience and to allow us to deliver the type of
            content and product offerings in which you are most interested.
      </li>
          <li>To improve our website in order to better serve you.</li>
          <li>
            To allow us to better service you in responding to your customer service
            requests.
      </li>
          <li>To administer a contest, promotion, survey or other site feature.</li>
          <li>To quickly process your transactions.</li>
          <li>
            To send periodic emails regarding your order or other products and
            services.
      </li>
        </ul>
        <h2 className="text-left font-semibold pt-10 pb-10">CREDIT CARD DETAILS</h2>
        <p>
          Credit card details entered on our APP are not captured or stored on
          Crowdconnect server. All credit card transaction and storage of credit
          card information are handled by our gateway provider Paydock
          (www.paydock.com) who is PCI-DSS compliant.
    </p>
        <h2 className="text-left font-semibold pt-10 pb-10">HOW DO WE PROTECT VISITOR INFORMATION?” OPEN</h2>
        <p>
          We use regular Malware Scanning. Your personal information is contained
          behind secured networks and is only accessible by a limited number of
          persons who have special access rights to such systems, and are required
          to keep the information confidential. In addition, all sensitive/credit
          information you supply is encrypted via Secure Socket Layer (SSL)
          technology.
    </p>
        <p>
          We implement a variety of security measures when a user places an order
          enters, submits, or accesses their information to maintain the safety of
          your personal information. Unfortunately, no data transmission over the
          internet can be guaranteed to be totally secure.
<div>        However, we will endeavour to take reasonable steps to protect the
        personal information you may transmit to us or from our website or App.</div>

      All transactions are processed through a gateway provider (Braintree
      [Paypal]) and are not stored or processed on our servers.
    </p>
        <h2 className="text-left font-semibold pt-10 pb-10">THIRD PARTY DISCLOSURE”</h2>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information.
    </p>
        <h2 className="text-left font-semibold pt-10 pb-10">THIRD PARTY LINKS” OPEN</h2>
        <p>
          Occasionally, at our discretion, we may include or offer third party
          products or services on our website. These third party sites have separate
          and independent privacy policies. We therefore have no responsibility or
          liability for the content and activities of these linked sites.
          Nonetheless, we seek to protect the integrity of our site and welcome any
          feedback about these sites.
    </p>
        <h2 className="text-left font-semibold pt-10 pb-10">DISCLOSURE</h2>
        <p>
          Apart from where you have consented or disclosure is necessary to achieve
          the purpose for which it was submitted, personal information may be
          disclosed in special situations where we have reason to believe that doing
          so is necessary to identify, contact or bring legal action against anyone
          damaging, injuring, or interfering (intentionally or unintentionally) with
          our rights or property, users, or anyone else who could be harmed by such
          activities. Also, we may disclose personal information when we believe in
          good faith that the law requires disclosure.
    </p>
        <p>
          We may engage third parties to provide you with goods or services on our
          behalf. In that circumstance, we may disclose your personal information to
          those third parties in order to meet your request for goods or services.
    </p>
        <p>
          If there are any questions regarding this privacy policy you may contact
          us.
    </p>
      </div>
    </div>

  </Layout>
);
